import repository from './repository'

const resource = 'orders'

export default {
  index (params) {
    return repository.get(resource, { params })
  },
  edit (id) {
    return repository.get(`${resource}/${id}/edit`)
  },
  show (id) {
    return repository.get(`${resource}/${id}/show`)
  },
  store (data) {
    return repository.post(resource, data)
  },
  update (id, data) {
    return repository.put(`${resource}/${id}/edit`, data)
  },
  validate (data) {
    return repository.post(`${resource}/validate`, data)
  },
  updateInvoiced (id, data) {
    return repository.put(`${resource}/${id}/invoiced`, data)
  },
  billings (params) {
    return repository.get(`${resource}/billings`, { params })
  },
  exportV1 (params) {
    const conf = {
      headers: {
        Accept: 'application/xlsx',
      },
      responseType: 'blob',
      params,
    }
    return repository.get(`${resource}/export-v1`, conf)
  },
  attachInvoice (id) {
    return repository.post(`${resource}/${id}/attach-invoice`)
  },
  deliver (id) {
    return repository.put(`${resource}/${id}/deliver`)
  },
  printReceipt (id) {
    const conf = {
      headers: {
        Accept: 'application/pdf',
      },
      responseType: 'text', 
    }
    return repository.get(`${resource}/${id}/print-receipt`, conf)
  },
}
