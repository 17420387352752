import searchDelay from '@/libs/searchDelay.extra'
import { errorHandler } from '@/libs/api-helper.extra'

export default class VueGetApi {
  constructor (Vue, { loading = null, error = null }) {
    this.Vue = Vue
    this.setLoadingVariableName(loading)
    this.setErrorVariableName(error)
    this.setDelay()
  }

  setDelay () {
    this.delay = searchDelay()
  }

  setErrorVariableName (name) {
    this.errorVariable = name
  }

  setLoadingVariableName (name) {
    this.loadingVariable = name
  }

  setLoading (isloading = true) {
    this.Vue[this.loadingVariable] = isloading
  }

  setErrors (e) {
    this.Vue[this.errorVariable] = errorHandler(e)
  }

  clearErrors () {
    this.Vue[this.errorVariable] = []
  }

  execute (repository, args = [], statusOkCallback = () => {}, statusErrorCallback = () => {}, afterRequestCallback = () => {}) {
    this.setLoading(true)
    this.clearErrors()
    this.delay(() => {
      repository(...args)
        .then(response => {
          statusOkCallback(response)
        })
        .catch(e => {
          this.setErrors(e)
          statusErrorCallback(e)
        })
        .then(() => {
          this.setLoading(false)
          afterRequestCallback()
        })
    })
  }

  send (repository, statusOkCallback = () => {}, statusErrorCallback = () => {}, afterRequestCallback = () => {}) {
    this.setLoading(true)
    this.clearErrors()
    repository
      .then(response => {
        statusOkCallback(response)
      })
      .catch(e => {
        this.setErrors(e)
        statusErrorCallback(e)
      })
      .then(() => {
        this.setLoading(false)
        afterRequestCallback()
      })
  }
}