<template lang="pug">
v-stepper(
  v-model="page"
)
  base-snackbar(
    :errors.sync="orderPostErrors"
  )
  v-stepper-header
    template(v-for="(item, index) in steps")
      v-stepper-step(
        :complete="page > item.step"
        :step="item.step"
        :editable="page > item.step"
      )
        span {{ item.label }}
      v-divider(
        v-if="index !== (steps.length - 1)"
      )
  v-stepper-items
    v-stepper-content(step="1")
      create-order(
        :input-fields.sync="orders"
        :error-messages="$objectChangeKeyCase(orderPostErrors)"
      )
      v-btn(
        color="primary"
        @click="validateOrders"
        :loading="orderPosting"
      )
        span Continue
    v-stepper-content(step="2")
      create-order-detail(
        :order-id="orders.id"
        :order-status-id="orders.orderStatusId"
        :error-messages="orderPostErrors"
        :items.sync="orderDetails"
        :revenueSourceId.sync="revenueSourceId"
      )
      v-btn(
        color="primary"
        @click="storeOrder"
        :loading="orderPosting"
      )
        span Save
</template>
<script>
import orderRepository from '@/repositories/order.repository'
import VuePostApi from '@/libs/classes/VuePostApi.class'
import { postVars } from '@/libs/api-helper.extra'

export default {
  name: 'CreateOrderProcess',
  components: {
    createOrder: () => import('./../forms/OrderForm'),
    createOrderDetail: () => import('./details/Create'),
  },
  data: () => ({
    page: 1,
    steps: [
      { step: '1', label: 'Order' },
      { step: '2', label: 'Details' },
    ],
    ...postVars('order'),
    orders: {},
    revenueSourceId: null,
    orderDetails: [],
  }),
  methods: {
    validateOrders() {
      if (this.orderPosting) return
      const orderPostRequestVarNames = this.getOrderPostRequestVarNames()
      const postApiRequest = new VuePostApi(this, orderPostRequestVarNames)
      const orders = this.$objectChangeKeyCase(this.orders, 'camelToSnakeCase')
      const repository = orderRepository.validate(orders)
      postApiRequest.send(repository, this.orderPostRequestStatusOk)
    },
    orderPostRequestStatusOk() {
      this.page = 2
    },
    orderStorePostRequestStatusOk({ data }) {
      this.$router.push({ name: 'orders.edit', params: { orderId: data } })
    },
    storeOrder() {
      if (this.orderPosting) return
      const orderPostRequestVarNames = this.getOrderPostRequestVarNames()
      const postApiRequest = new VuePostApi(this, orderPostRequestVarNames)
      const data = this.getOrderData()
      
      const repository = orderRepository.store(data)
      postApiRequest.send(repository, this.orderStorePostRequestStatusOk)
    },
    getOrderData() {
      const { revenueSourceId } = this
      return {
        order: this.$objectChangeKeyCase({ ...this.orders, revenueSourceId }, 'camelToSnakeCase'),
        details: this.orderDetails.map(item => this.$objectChangeKeyCase(item, 'camelToSnakeCase')),
      }
    },
    getOrderPostRequestVarNames() {
      const BASE_NAME = 'order'
      return {
        loading: `${BASE_NAME}Posting`,
        error: `${BASE_NAME}PostErrors`,
      }
    },
  },
}
</script>
